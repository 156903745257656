import React from 'react'
import Banner from '../../components/banner';
import styled from 'styled-components';

const Section = styled.section`
  min-height: calc(100% - 284px);
`;

const Kids = () => (
  <>
    <Banner
      title="Kids Martial Arts"
      height={{ mobile: '13rem', desktop: '27rem' }}
      image=" bg-[url('../public/img/kids-banner.jpg')] bg-cover bg-center"
    />

    <Section className="container px-6 py-8 md:flex-row md:items-start md:gap-12">
      <div>
        <p>
          One Family Martial Arts&apos; Kids Martial Arts and Kinder Kicks classes are a fun activity that
          will enhance every aspect of your child&apos;s life. One Family Martial Arts&apos; dedicated
          instructors have designed an incredible Kids Martial Arts program that focuses on life skills
          such as goal-setting, integrity and discipline to make your child a great person inside and
          outside of the dojang.
        </p>

        <h2 className="block my-6 text-3xl uppercase text-red-800">
          Kids Programs
        </h2>

        <h3
          id="kinder-kubs"
          className="mb-2 text-2xl text-stone-600"
        >
          Kinder Kubs (0-5 yo)
        </h3>
        <p>
          Kinder Kubs is a great group for your little ones to start out in, have some fun and socialise
          with other children their age.
        </p>

        <h3
          id="tiny-tigers"
          className="mt-6 mb-2 text-2xl text-stone-600"
        >
          Tiny Tigers (3-4 yo)
        </h3>
        <p>
          One Family Martial Arts provides a safe, comfortable, family environment for our Tiny Tigers
          that is brimming with encouragement and inspiration to help your child grow physically,
          mentally and socially. Our students gain confidence, basic self-defence and bully prevention
          techniques as well as important life skills.
        </p>

        <h3
          id="pandas"
          className="mt-6 mb-2 text-2xl text-stone-600"
        >
          Pandas (5-7 yo)
        </h3>
        <p>
          Our Pandas program offers a fun and exciting martial arts class that teaches focus and respect
          for adults and their fellow students along with basic martial arts techniques that develop
          general movement skills such as coordination, balance, strength and self-defence.
        </p>

        <h3
          id="wolves"
          className="mt-6 mb-2 text-2xl text-stone-600"
        >
          Wolves (8-11 yo)
        </h3>
        <p>
          With One&apos;s Wolves program, we help our students not only pursue results, but to love the
          journey. Our students will gain unshakeable confidence, self-control and improved self-esteem.
          Our Wolves program focuses on important values such as manners, focus, discipline and attention
          to details as keys to efficient learning.
        </p>
      </div>

      <div className="w-full">
        <h2 className="block my-4 text-3xl uppercase text-red-800 md:mt-0">
          Skill Development
        </h2>

        <ul className="m-0 p-0 list-none border border-stone-300 divide-y divide-stone-300">
          <li className="p-2">Greater Overall Health &amp; Fitness</li>
          <li className="p-2">Learn Bully Prevention &amp; Self-Defence</li>
          <li className="p-2">Increased Concentration &amp; Focus</li>
          <li className="p-2">Develop Character &amp; Discipline</li>
          <li className="p-2">Improved Goal-Setting</li>
          <li className="p-2">Increased Confidence &amp; Self-Esteem</li>
        </ul>
      </div>
    </Section>
  </>
);

export default Kids;
