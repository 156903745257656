import React from 'react'
import ModuleBeginner from '../modules/module-beginner';
import ModuleTrial from '../modules/module-trial';
import ModulePrograms from '../modules/module-programs';
import ModuleFairplay from '../modules/module-fairplay';

const Home = () => {
  return (
    <>
      <div className="min-h-56 flex justify-center items-center">
        <h1 className="text-4xl font-bold text-red-800 text-center font-headingtext">Martial Arts for Fitness, Fun and Self Defence</h1>
      </div>
      <ModuleBeginner />
      <ModuleTrial />
      <ModulePrograms />
      <ModuleFairplay />
    </>
  );
};

export default Home;
