import React from 'react'
import Banner from '../../components/banner';
import styled from 'styled-components';

const Section = styled.section`
  min-height: calc(100% - 284px);
`;

const BradReid = () => (
  <>
    <Banner title="Sabom Brad Reid" />

    <Section className="container px-6 py-8 items-start md:flex-row md:items-start md:gap-12">
      <div>
        <p className="mb-4">
          Sabom Brad first met Grand master Hope training at the Trinity Beach Red Cross Hall in 1993.
          After a 20 year break due to &apos;Life getting in the way&apos; as Master Brad liked to say,
          training resumed. After bringing his son to train in 2013, he began training and soon after
          that helping to take classes.
        </p>

        <p className="mb-4">
          Upon achieving his black belt, he became head trainer. After the death of Grand master Brad
          Hope, Sabom Marcarla and he took the running of the school.
        </p>

        <p className="mb-4">
          Sabom Brad is currently 3rd dan bo and is looking forward to his next grading as 4th dan is
          considered 'master' level.
        </p>
      </div>

      <img
        className="hidden rounded-lg md:block md:shadow-lg md:shadow-stone-700/30"
        src="/img/brad-portrait.jpg"
        alt="Sabom Brad Reid"
      />
    </Section>
  </>
);

export default BradReid;