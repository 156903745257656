import React from 'react';
import Navigation from './navigation/navigation';
import styled from 'styled-components';
import LinkLogo from './link-logo';
import FacebookLogo from './facebook-logo';

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  box-shadow: 0 20px 25px -5px var(--color-shadow),
    0 8px 10px -6px  var(--color-shadow);
  z-index: 999;
`;

const BlackLine = styled.div`
  height: 1.25rem;
  background-color: var(--color-secondary);
`;

const RedLine = styled.div`
  height: 2.5rem;
  background-color: var(--color-primary);
`;

const RedLineContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  text-align: right;
`;

const FacebookLink = styled.a`
  display: inline-block;
`;

const NavContainer = styled.div`
  height: 3rem;
  background-color: var(--color-content-bg);
`;

const HeaderContent = styled.div`
  position: relative;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: end;

  @media screen and (min-width: 1024px) {
  }
`;

const Header = () => {
  return (
    <StyledHeader>
      <BlackLine />
      <RedLine>
        <RedLineContent>
          <FacebookLink href="#" target="_blank">
            <FacebookLogo size={28} />
          </FacebookLink>
        </RedLineContent>
      </RedLine>
      <NavContainer>
        <HeaderContent>
          <LinkLogo type="header" />
          <Navigation />      
        </HeaderContent>
      </NavContainer>
    </StyledHeader>
  );
};

export default Header;
