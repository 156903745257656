import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledLi = styled.li`
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--nav-color-border);
  }
`;

const StyledNavigationSubLink = styled(NavLink)`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  
  @media screen and (min-width: 1024px) {
    padding: 0.5rem 1rem;
  }

  &:hover {
    background-color: var(--color-stone-200);
  }

  &.active {
    color: var(--color-primary);
  }

  &.active:hover {
    color: var(--color-primary-hover);
  }
`;

const NavigationSecondary = ({ text, to }) => (
  <StyledLi>
    <StyledNavigationSubLink to={to}>{text}</StyledNavigationSubLink>
  </StyledLi>
);

export default NavigationSecondary;
