import React from 'react';
import Banner from '../components/banner';
import CardInstructor from '../components/card-instructor';
import ModuleTrial from '../modules/module-trial';
import ModuleFairplay from '../modules/module-fairplay';
import { instructorData } from '../content/instructors';

const Instructors = () => {
  return (
    <>
      <Banner title="Instructors" />

      <section className="instructors">
        <div className="relative max-w-screen-xl mx-auto h-full flex flex-col items-stretch justify-start md:flex-row">
          {instructorData.map((instructor) => <CardInstructor key={instructor.id} {...instructor} />)}
        </div>
      </section>

      <ModuleTrial />
      <ModuleFairplay />
    </>
  );
};

export default Instructors;
