import React from 'react';
import styled from 'styled-components';

const StyledBanner = styled.div`
  position: relative;
  background-color: var(--color-red-800);
  height: ${(props) => `${props.height.mobile}`};

  @media screen and (min-width: 1024px) {
    height: ${(props) => `${props.height.desktop}`};
  }
`;

const BannerBottom = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--color-stone-700-80);
  height: 2rem;
`;

const BannerContainer = styled.div`
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
`;

const H1 = styled.h1`
  position: absolute;
  left: 1.5rem;
  bottom: 0.5rem;
  font-size: 3rem;
  line-height: 1;
  color: var(--color-stone-50);
  text-transform: uppercase;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5));
`;

const Banner = ({
  height = { mobile: '8rem', desktop: '8rem' },
  image = '',
  title,
}) => (
  <StyledBanner className={`${image}`} height={height}>
    <BannerBottom>
      <BannerContainer>
        <H1>{title}</H1>
      </BannerContainer>
    </BannerBottom>
  </StyledBanner>
);

export default Banner;
