import React from 'react';
import Button from '../components/button';

const ModuleProgramItem = ({ program, index }) => {
  const { title, description, buttonText, image, url } = program;

  return (
    <div className={`min-h-96 flex items-center text-stone-800 bg-stone-50 border-b-8 border-stone-800 md:border-b-0 ${index%2 === 1 ? 'md:bg-stone-200' : ''}`}>
      <div className={`max-w-screen-lg mx-auto flex flex-col-reverse gap-y-4 items-center justify-around md:gap-x-4 md:flex-row ${index%2 === 0 ? 'md:flex-row-reverse' : ''}`}>
        <div className={`flex flex-col gap-4 max-w-96 pb-6 items-center text-center md:text-left ${index%2 === 0 ? 'md:text-right' : ''}`}>
          <h1 className={`block w-full text-4xl ${index%2 === 0 ? 'md:text-right' : ''}`}>
            {title}
          </h1>
          <p className="px-4 md:px-1">{description}</p>
          <div className="w-full">
            <Button to={url}>{buttonText}</Button>
          </div>
        </div>    

        <img
          className="md:rounded-3xl"
          src={image}
          alt=""
        />
      </div>
    </div>
  );
};

export default ModuleProgramItem;
