import React from 'react';
import Button from '../components/button';
import styled from 'styled-components';

const Section = styled.section`
  padding: 1.4rem 0 2rem;
  color: var(--color-primary-text);
  background-color: var(--color-primary);
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  row-gap: 1rem;
  max-width: var(--max-screen-width);
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
  }
`;

const LeftColumn = styled.div`
  text-align: center;
`;

const H1 = styled.h1`
  font-size: 1.875rem;
  font-family: var(--heading-text);
  @media screen and (min-width: 1024px) {
    font-size: 2.25rem;
    padding-bottom: 0.5rem;
  }
`;

const P = styled.p`
  padding: 0 2rem;
  font-weight: 500;
  @media screen and (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

const ModuleBeginner = () => {
  return (
    <Section>
      <SectionContainer>
        <LeftColumn>
          <H1>Beginners classes enrolling now!</H1>
          <P>Martial Arts in Cairns teaches discipline & self confidence!</P>
        </LeftColumn>

        <Button variant="secondary">Learn more</Button>
      </SectionContainer>
    </Section>
  );
};

export default ModuleBeginner;
