import React from 'react';
import Button from '../components/button';
import styled from 'styled-components';

const StyledSection = styled.section`
  padding-bottom: 32px;
  text-transform: uppercase;
  text-align: center;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(219,219,219,1) 100%);

  @media (min-width: 1024px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const ModuleFairplay = ({ hideButton = false }) => {
  return (
    <StyledSection >
      <div className="container flex-col-reverse justify-center md:flex-row-reverse">
        <div className="md:ml-8 text-stone-700 md:text-left">
          <h1 className="text-4xl leading-4 font-semibold md:text-5xl md:leading-7 md:ml-28">We accept</h1>
          <p className="text-7xl font-headingtext md:text-9xl md:leading-[110px]">Fairplay</p>
          <p className="ml-16 pb-4 leading-[0.15] text-3xl md:ml-0 md:leading-[0.1] md:ml-12">Vouchers</p>

          {!hideButton && (
            <p className="md:ml-24"><Button to="/fairplay">Read more</Button></p>
          )}
        </div>
        
        <img
          className="rounded-image"
          src="img/group-back-fist.jpg"
          alt=""
        />
      </div>
    </StyledSection>
  );
};

export default ModuleFairplay;
