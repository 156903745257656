import React from 'react'
import Banner from '../../components/banner';

const Hapkido = () => (
  <>
    <Banner title="Hapkido" />

    <div className="container px-6 py-8 md:flex-row md:items-start md:gap-12">
      <div>
        <p>
          Hapkido is an extremely versatile and comprehensive discipline of self-defence that includes
          an extensive variety of strikes, kicks, joint-locks, pressure points, grappling and weapon
          disarming techniques. One FMA&apos;s Hapkido program will teach you how to use your strength and
          use your attacker&apos;s strength against them. Our Hapkido program is easy and exciting to
          learn for men and women of all ages and abilities.
        </p>

        <h2 className="block my-6 text-3xl text-red-800">
          Learn pure self-defence at One Family Martial Arts!
        </h2>
        <p>
          In this program students will learn to handle attacks from a standing, grappling and ground
          position. Our complete Hapkido curriculum will help every student to feel comfortable about
          their ability to protect themselves no matter the situation.
        </p>
          
        <h2 className="block my-6 text-3xl text-red-800">
          Learn the most effective self-defence &amp; hapkido techniques in Smithfield!
        </h2>
        <p>
          Get protected with One FMA&apos;s Hapkido classes. Fill out the short form on the side of this
          page to get all the information you need to get started today!
        </p>
      </div>
          
      <div className="w-full">
        <h2 className="block my-4 text-3xl uppercase text-red-800 md:mt-0">
          Skill Development
        </h2>

        <ul className="m-0 p-0 list-none border border-stone-300 divide-y divide-stone-300">
          <li className="p-2">Learn Effective Self-Defence &amp; Tae Kwon Do Techniques</li>
          <li className="p-2">Increased Physical Fitness</li>
          <li className="p-2">Improved Confidence &amp; Self-Esteem</li>
          <li className="p-2">Improved Coordination &amp; Motor Skills</li>
          <li className="p-2">Increased Focus &amp; Concentration</li>
        </ul>
      </div>
    </div>
  </>
);

export default Hapkido;
