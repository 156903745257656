import React from 'react';
import Banner from '../components/banner';
import styled from 'styled-components';
import ModuleFairplay from '../modules/module-fairplay';

const FairplayContainer = styled.div`
  max-width: 1240px;
  margin: 3rem auto;
  padding: 0 1rem;

  @media screen and (min-width: 1024px) {
    padding: 0;
  }
`;

const Heading2 = styled.h2`
  margin: 1.5rem 0 0.5rem 0;
  font-size: 1.25rem;
  color: var(--color-primary);
`;

const P = styled.p`
  margin: 0.25rem 0;
`;

const Li = styled.li`
  margin: 0 0 0 1.5rem;
  padding: 0;
  list-style: disc;
`

const A = styled.a`
  color: var(--link-color);
  text-decoration: underline;
  
  &:hover {
    text-decoration: none;
  }
`;

const Fairplay = () => {
  return (
    <>
      <Banner title="Fairplay Vouchers" />

      <FairplayContainer>
        <P>One Family Martial Arts accepts FairPlay vouchers which can be used for payments towards regular classes. Vouchers can not be used for the Trial period.</P>

        <Heading2>1. Check if your child is eligible</Heading2>

        <P>You can apply if your child:</P>

        <ul>
          <Li>is a Queensland resident aged from 5 to 17 years (inclusive) at the time of application</Li>

          <Li>did not receive a FairPlay voucher this calendar year</Li>

          <Li>
            holds, or whose parent, carer or guardian holds, a valid Services Australia
            {' '}
            <A href="https://web.archive.org/web/20230222051321/https://www.humanservices.gov.au/customer/services/centrelink/health-care-card">Health Care Card</A>
            {' '}
            or <A href="https://web.archive.org/web/20230222051321/https://www.humanservices.gov.au/customer/services/centrelink/pensioner-concession-card">Pensioner Concession Card</A>
            {' '}
            with the child&apos;s name on it.
          </Li>
        </ul>

        <Heading2>2. Make sure your activity is available</Heading2>

        <P>
          To confirm that One FMA is a FairPlay activity provider, you can search in
          the <A href="https://web.archive.org/web/20230222051321/https://www.qld.gov.au/recreation/sports/funding/fairplay/find-provider">
            activity provider directory
          </A>. If you find that One FMA doesn't appear in the list, please contact us as it may not yet
          be listed on the website.
        </P>

        <Heading2>3. Read the terms and conditions</Heading2>

        <P>Read and understand the FairPlay voucher application <A href="https://web.archive.org/web/20230222051321/https://www.qld.gov.au/recreation/sports/funding/fairplay/terms">terms and conditions</A>.</P>

        <Heading2>4. Access the Enquire online system</Heading2>

        <P>You will need to <A href="https://web.archive.org/web/20230222051321/https://sr-cp.sr-enquire.cloud/register">register for an Enquire account</A> before you can apply for a voucher.</P>

        <P>This information is taken from the official Queensland Government website, please visit <A rel="noreferrer noopener" href="https://web.archive.org/web/20230222051321/https://www.qld.gov.au/recreation/sports/funding/fairplay/apply" target="_blank">FairPlay Vouchers</A> for more information.</P>
      </FairplayContainer>

      <ModuleFairplay hideButton />
    </>
  );
};

export default Fairplay;
