import React from 'react';
import Button from './button';

const CardInstructor = ({ title, image, description, url }) => {
  const colorPrimary = 'red-800';

  return (
    <a
      className="relative flex flex-col flex-1 w-full items-center gap-4 px-4 py-8 text-center bg-gradient-to-t from-stone-200 to-stone-50 md:pb-28 md:bg-none"
      href={url}
    >
      <h2 className={`text-3xl text-${colorPrimary} md:min-h-20`}>
        {title}
      </h2>

      <div
        className="w-40 h-40 rounded-full border border-stone-400 bg-center bg-cover"
        style={{ backgroundImage: `url(${image}`}}
      />

      {description}

      <div className="md:absolute md:bottom-12">
        <Button variant="secondary">
          View Profile
        </Button>
      </div>
    </a>
  );
};

export default CardInstructor;
