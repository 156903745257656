import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/home";
import Programs from './pages/programs';
import Kids from './pages/programs/kids';
import Taekwondo from './pages/programs/taekwondo';
import Hapkido from './pages/programs/hapkido';
import FairPlay from './pages/fairplay';
import Instructors from './pages/instructors';
import SungSooLee from './pages/instructors/sung-soo-lee';
import CindyRohan from './pages/instructors/cindy-rohan';
import BradReid from './pages/instructors/brad-reid';
import MacarlaDebel from './pages/instructors/macarla-debel';
import Layout from './ui/layout';
import './reset.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/programs',
        element: <Programs />,
      },
      {
        path: '/programs/kids',
        element: <Kids />,
      },
      {
        path: '/programs/taekwondo',
        element: <Taekwondo />,
      },
      {
        path: '/programs/hapkido',
        element: <Hapkido />,
      },
      {
        path: '/instructors',
        element: <Instructors />,
      },
      {
        path: '/instructors/sung-soo-lee',
        element: <SungSooLee />,
      },
      {
        path: '/instructors/cindy-rohan',
        element: <CindyRohan />,
      },
      {
        path: '/instructors/brad-reid',
        element: <BradReid />,
      },
      {
        path: '/instructors/macarla-debel',
        element: <MacarlaDebel />,
      },
      {
        path: '/fairplay',
        element: <FairPlay />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
