import React from 'react'
import Banner from '../../components/banner';

const Taekwondo = () => (
  <>
    <Banner title="Taekwondo" />

    <div className="container px-6 py-8 md:flex-row md:items-start md:gap-12">
      <div>
        <p>
          If you want to achieve all your fitness goals, get a great cardio workout and enjoy the added
          benefit of learning effective self-defence, Taekwondo classes in Smithfield from
          One Family Martial Arts are right for you.
        </p>
        <p>
          Our Taekwondo program is more than just a workout, it&apos;s a program that teaches students
          to maintain good habits not only in physical activity and martial arts, but life in general.
          Through One&apos;s well structured classes, students of all ages are able to train in a safe
          and supportive environment enabling them to form friendships that support them on their way
          to achieving their goals inside and outside of the dojo.
        </p>

        <h2 className="block my-6 text-3xl text-red-800">
          Get fit &amp; learn incredible Taekwondo skills in Smithfield!
        </h2>
        <p>
          Taekwondo is a Korean martial art and combat sport as well as the National sport of South
          Korea. Often translated as “The way of the foot and fit”, Taekwondo is an excellent
          way to improve your balance, strength, speed, flexibility and stamina.
        </p>
        <p>
          One Family Martial Arts&apos; Taekwondo classes will teach you a wide variety of kicks and
          punches including reverses, jumping, spinning and sliding, often in combination. Our
          Taekwondo training includes a system of blocks, punches, and open-handed strikes that are
          effective for adults and teens no matter their fitness level.
        </p>

        <h2 className="block my-6 text-3xl text-red-800">
          Join now &amp; burn calories while learning effective and practical self-defence!
        </h2>
        <p>
          Fill out the short form on the side of this page and have all the information you need to
          get started immediately sent to your inbox. Start your transformation today at One&apos;s
          Family Martial Arts!
        </p>
      </div>

      <div className="w-full">
        <h2 className="block my-4 text-3xl uppercase text-red-800 md:mt-0">
          Skill Development
        </h2>

        <ul className="m-0 p-0 list-none border border-stone-300 divide-y divide-stone-300">
          <li className="p-2">Learn Effective Self-Defence &amp; Tae Kwon Do Techniques</li>
          <li className="p-2">Increased Physical Fitness</li>
          <li className="p-2">Improved Confidence &amp; Self-Esteem</li>
          <li className="p-2">Improved Coordination &amp; Motor Skills</li>
          <li className="p-2">Increased Focus &amp; Concentration</li>
        </ul>
      </div>
    </div>
  </>
);

export default Taekwondo;
