import React from 'react';
import ModuleProgramItem from './module-program-item';
import { programsExcerpt } from '../content/programs';

const ModulePrograms = () => {
  return (
    <section>
      {programsExcerpt.map((program, index) => (
        <ModuleProgramItem
          key={program.title}
          program={program}
          index={index}
        />
      ))}
    </section>
  );
};

export default ModulePrograms;
