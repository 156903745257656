import React from 'react';
import Button from '../components/button';

const ModuleTrial = () => {
  return (
    <section className="pt-6 pb-8 text-stone-50 bg-stone-700 md:py-16">
      <div className="container flex-col-reverse md:flex-row">
        <div className="mt-3 uppercase text-center md:mt-0">
          <h1 className="ml-8 text-4xl leading-8 font-bold md:text-6xl md:leading-10 md:ml-12">
            2 Week Trial
          </h1>
          <p className="mr-12 text-6xl md:text-9xl font-headingtext">Only $55</p>
          <p className="text-2xl leading-[0.45] mb-4 md:ml-24 md:mb-10 md:text-4xl md:leading-3">Includes Uniform</p>

          <div className="relative">
            <Button to="/programs">Find a class</Button>
          </div>
        </div>
        <img
          className="md:rounded-2xl"
          src="/img/group-mixed-01.jpg"
          alt="Mixed group shot"
        />
      </div>
    </section>
  );
};

export default ModuleTrial;
