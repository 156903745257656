import React from 'react';
import Button from './button';
import { Link } from 'react-router-dom';
import LinkLogo from './link-logo';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  border-top: 5px solid var(--color-content-bg);
  color: var(--color-content-bg);
  font-size: 0.875rem;
`;

const FooterContainer = styled.div`
  background-color: var(--color-secondary);
  border-top: 20px solid var(--color-primary);
`;

const Row1 = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-width: var(--max-screen-width);
  margin: 1rem auto;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  @media screen and (min-width: 1024px) {
    margin: 0 auto;
    padding: 1rem;
    flex-direction: row;
    text-align: left;
  }
`;

const Row2 = styled.div`
  color: var(--color-secondary);
  background-color: var(--color-content-bg);
  font-size: 0.875rem;
`;

const Row2Container = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-width: var(--max-screen-width);
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 1rem;
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <Row1>
          <div className="">
            <h1 className="text-3xl md:leading-7 font-headingtext">One Family Martial Arts</h1>
            <p className="address">Unit 2, 7-11 Salvado Drive,<br />Smithfield, QLD 4878</p>

            <div className="social">
              <a href="https://web.archive.org/web/20230222051033/https://www.facebook.com/bradhopefma" target="_blank" rel="noopener" className="p4-facebook"></a>
              <a href="https://web.archive.org/web/20230222051033/https://instagram.com/BRADHOPEFMA" target="_blank" rel="noopener" className="p4-instagram"></a>
            </div>
          </div>

          <div className="flex flex-col items-center md:flex-row">
            <Button to="tel:07 4038 2633" variant="secondary">07 4038 2633</Button>
          </div>
          <div className="hidden relative w-36 h-16 md:block">
            <div className="md:absolute md:-bottom-8 md:right-0">
              <LinkLogo size={152} />
            </div>
          </div>
        </Row1>
        <Row2>
          <Row2Container>
            <p>
              &copy; 2024 One FMA. <span>All Rights reserved</span>
            </p>
            <p className="hidden">
              {/* <a href="#">ph4se.com.au</a> */}
            </p>
          </Row2Container>
        </Row2>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
