import React from 'react'
import Banner from '../../components/banner';
import styled from 'styled-components';

const Section = styled.section`
  min-height: calc(100% - 284px);
`;

const CindyRohan = () => (
  <>
    <Banner title="Kwanjang Cindy Rohan" />

    <Section className="container px-6 py-8 items-start md:flex-row md:items-start md:gap-12">
      <div>
        <p>
          KwanJang Cindy is the head instructor at One Family Martial Arts (formerly Brad Hope FMA) and has
          been training for over 20 years. She currently holds a 5th Dan in Taekwondo and a 3rd Dan in Moo
          Hak Kwan Hapkido.
        </p>

        <h2 className="block my-4 text-3xl uppercase text-red-800">
          Qualifications
        </h2>
        <ul className="m-0 p-0 ml-6 list-disc space-y-2">
          <li>5th Dan Taekwondo</li>
          <li>3rd Dan Hapkido</li>
        </ul>

        <h2 className="block my-4 text-3xl uppercase text-red-800">
          Personal History
        </h2>
        <p className="mb-4">KwanJang Cindy Marie Rohan was born in Brisbane QLD, Australia 9th of May 1967.</p>

        <p className="mb-4">Joined Brad Hope Taekwondo Innisfail in 2002 under the instruction of Chrys Tait</p>

        <p className="mb-4">Moved to Brad Hope Taekwondo Cairns in 2004 under the instruction of Master Brad Hope</p>

        <p className="mb-4">Trained members of Brad Hope Taekwondo Cairns 2004-2006</p>

        <p className="mb-4">Awarded 1st Dan Jidokwan Taekwondo 2006 (Sung Soo Lee)</p>

        <p className="mb-4">Joined Chungdokwan Taekwondo 2007 under the instruction of Master Dale Williams</p>

        <p className="mb-4">Awarded 2nd Dan Chungdokwan Taekwondo 2008 (David Jordinson)</p>

        <p className="mb-4">Awarded 3rd Dan Chungdokwan Taekwondo 2011 (Dale Williams)</p>

        <p className="mb-4">Returned to Brad Hope Family Martial Arts 2013</p>

        <p className="mb-4">Awarded 4th Dan Jidokwan Taekwondo 2016 (Sung Soo Lee)</p>

        <p className="mb-4">Awarded 5th Dan Jidokwan Taekwondo 2020 (Sung Soo Lee)</p>

        <p className="mb-4">Awarded 1st Dan Moo Hak Kwan Hapkido 2018 (Sung Soo Lee)</p>

        <p className="mb-4">Commenced training of Brad Hope Family Marital Art students after KwanJang&apos;s (Master Brad
        Hope) passing 2019</p>

        <p className="mb-4">Awarded 2nd Dan Moo Hak Kwan Hapkido 2019 (Sung Soo Lee)</p>

        <p className="mb-4">Awarded 3rd Dan Moo Hak Kwan Hapkido 2021 (Sung Soo Lee)</p>

        <h2 className="block my-4 text-3xl uppercase text-red-800">
          Other Achievements
        </h2>
        <ul className="m-0 p-0 ml-6 list-disc space-y-2">
          <li>Classical Ballet Training- Examined under Royal Academy of Dance standards 1974- 1979</li>
          <li>Bachelor of Education 2006</li>
          <li>Certificate IV in Training and Assessment 2018</li>
        </ul>
      </div>

      <img
        className="hidden rounded-lg md:block md:shadow-lg md:shadow-stone-700/30"
        src="/img/cindy-portrait.jpg"
        alt="Kwanjang Cindy Rohan"
      />
    </Section>
  </>
);

export default CindyRohan;
