import React from 'react';

const CardPrograms = ({ program }) => {
  const {
    description,
    items,
    title,
  } = program;
  return (
    <div className="bg-gradient-to-b from-stone-200 to-stone-50 border-b-8 border-stone-500">
      <section className="container px-6 py-8 items-start md:flex-row md:gap-12 md:py-12">
        <div>
          <h2 className="text-3xl uppercase">{title}</h2>
          {description}
        </div>

        <div className={`flex flex-row flex-wrap justify-around gap-y-4 w-full md:flex-nowrap ${items.length >= 4 ? 'md:gap-x-2' : 'md:w-1/2 md:gap-x-20'}`}>
          {items.map(({ age = null, image, name, url }) => (
            <a key={name} className="w-32 md:w-44" href={url}>
              <img src={image} alt="" />
              <h3 className="text-lg text-center">{name} {age ? (<span className="block text-sm">{age}</span>) : ''}</h3>
            </a>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CardPrograms;
