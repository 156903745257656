import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const defaultStyle = css`
  display: inline-block;
  padding: 0.5rem 2rem;
  border-radius: 40px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  border-width: 3px;
  border-style: solid;
  transition: all 0.3s ease;

  @media screen and (min-width: 1024px) {
    font-size: 1.125rem;
  }
`;

const variantStyle = (variant) => {
  switch(variant) {
    case 'secondary':
      return css`
        color: var(--color-primary);
        border-color: var(--color-stone-700);
        background-color: var(--color-stone-50);

        &:hover {
          color: var(--color-stone-50);
          border-color: var(--color-stone-700);
          background-color: var(--color-primary);
        }
      `;
    default:
      return css`
        color: var(--color-stone-50);
        border-color: var(--color-stone-50);
        background-color: var(--color-primary);

        &:hover {
          color: var(--color-primary);
          border-color: var(--color-primary);
          background-color: var(--color-stone-50);
        }
      `;
  }
};

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => [
    'children', 'className', 'type', 'onClick'
  ].includes(prop)
})`
  ${defaultStyle}
  ${(props) => variantStyle(props.variant)}
`;

const StyledLink = styled(Link)`
  ${defaultStyle}
  ${(props) => variantStyle(props.variant)}
`;

const Button = ({
  className = '',
  children,
  to,
  type = 'button',
  variant = 'primary',
}) => {
  if (to) {
    return (
      <StyledLink to={to}>
        {children}
      </StyledLink>
    )
  }

  return (
    <StyledButton>
      {children}
    </StyledButton>
  );
};

export default Button;
