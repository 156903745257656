import React from 'react';

export const instructorData = [
  {
    id: 1,
    title: (<>Grand Master <span className="text-nowrap">Sung Soo Lee</span></>),
    description: (<>
      <p>9th Dan head master of Australia <span className="text-nowrap">Hapkido Moohakkwan</span></p>
      <p>9th Dan head master of Taekwondo <span className="text-nowrap">Jidokwan in Australia</span></p>
    </>),
    image: "/img/Master-Sung-Soo-Lee.jpg",
    url: "/instructors/sung-soo-lee",
  },
  {
    id: 2,
    title: "Kwanjang Cindy Rohan",
    description: (<>
      <p>5th Dan Taekwondo</p>
      <p>3rd Dan Hapkido</p>
    </>),
    image: "/img/cindy-portrait.jpg",
    url: "/instructors/cindy-rohan",
  },
  {
    id: 3,
    title: "Sabom Brad Reid",
    description: (<p>4th Dan Taekwondo</p>),
    image: "/img/brad-portrait.jpg",
    url: "/instructors/brad-reid",
  },
  {
    id: 4,
    title: "Sabom Macarla Debel",
    description: (<>
      <p>1st Dan Taekwondo</p>
    </>),
    image: "/img/macarla-portrait.jpg",
    url: "/instructors/macarla-debel",
  },
];
