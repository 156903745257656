import React from 'react';
import Banner from '../../components/banner';
import styled from 'styled-components';

const Section = styled.section`
  min-height: calc(100% - 284px);
`;

const MacarlaDebel = () => (
  <>
    <Banner title="Sabom Macarla Debel" />

    <Section className="container px-6 py-8 items-start md:flex-row md:items-start md:gap-12">
      <div>
        <p className="mb-4">
          Sabom Macarla has been involved in One FMA since 2008 when her then 2 year old son started
          training. After watching from the side she decided to begin her own training journey in 2009.
        </p>

        <p className="mb-4">
          Sabom Macarla took a break to raise her 4 children but returned in 2018 as a junior instructor
          when Grand Master Hope became ill. In November 2020 Sabom Brad and Sabom Macarla took over the
          running of Brad Hope FMA.
        </p>

        <p className="mb-4">
          Sabom Macarla enjoys teaching and passing on the knowledge she has learnt from Grand Master
          Hope.
        </p>
      </div>

      <img
        className="hidden rounded-lg md:block md:shadow-lg md:shadow-stone-700/30"
        src="/img/macarla-portrait.jpg"
        alt="Sabom Macarla Debel"
      />
    </Section>
  </>
);

export default MacarlaDebel;
