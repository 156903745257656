import React from 'react'
import Banner from '../../components/banner';
import styled from 'styled-components';

const Section = styled.section`
  min-height: calc(100% - 284px);
`;

const SungSooLee = () => (
  <>
    <Banner title="Grand Master Sung Soo Lee" />

    <Section className="container px-6 py-8 items-start md:flex-row md:items-start md:gap-12">
      <div>
        <p>
          Grand Master Sung Soo Lee is the 9th Dan head master of Australia Hapkido Moohakkwan (affiliated with the Korea Hapkido Federation), and is also the 9th Dan head master of Taekwondo Jidokwan in Australia (affiliated with the World Taekwondo Federation).
          7 Tigers Taekwondo and Hapkido are his representatives in the United States under his direction.
        </p>

        <h2 className="block my-4 text-3xl uppercase text-red-800">
          Qualifications
        </h2>
        <ul className="m-0 p-0 ml-6 list-disc space-y-2">
          <li>Bachelor Degree of Physical Education, Seoul University, 1963</li>
          <li>1st Dan Karate</li>
          <li>2nd Dan Judo, Korea Judo Association, 1983</li>
          <li>9th Dan Taekwondo Jidokwon, Korea Taekwondo Association HQ. 1993</li>
          <li>9th Dan Taekwondo Kukkiwon, World Taekwondo Federation H.Q. 1993</li>
          <li>9th Dan Hapkido, Korea Hapkido Federation. 1996.
        </li></ul>

        <h2 className="block my-4 text-3xl uppercase text-red-800">
          Personal History
        </h2>
        <p className="mb-4">Date and Place of Birth; 30/12/1936, Seoul, Korea.</p>

        <p className="mb-4"><strong>1953</strong>: Joined Taekwondo Jidokwan, Seoul, Korea</p>

        <p className="mb-4"><strong>1956</strong>: Taekwondo Jidokwan 1st Dan</p>

        <p className="mb-4"><strong>1958-60</strong>: U.S.A. 8th Military Army Taekwondo Instructor in Seoul &amp; Osan, Korea</p>

        <p className="mb-4"><strong>1959</strong>: Judo 1st Dan from Korea Judo Association Guitar Tuition School open Part time Guitar Performer in Night Club</p>

        <p className="mb-4"><strong>1961</strong>: Joined Hapkido Class in Korean Army</p>

        <p className="mb-4"><strong>1963</strong>: Bachelor Degree of Physical Education in Seoul University</p>

        <p className="mb-4"><strong>1963-73</strong>: Sports Writer in Orient Press</p>

        <p className="mb-4"><strong>1965</strong>: Korea Hapkido Association, Jungmookwan 1st Dan| Taekwondo Jidokwan Kunil School Open</p>

        <p className="mb-4"><strong>1968</strong>: Taekwondo Jidokwan Instructor Training</p>

        <p className="mb-4"><strong>1972</strong>: Korea Taekwondo Association Instructor Training Taekwondo Lecturer in Seoul National University Selected as Singapore National Team Coach by Korea Taekwondo Association for 2 year contract</p>

        <p className="mb-4"><strong>1973</strong>: Participation to 1st World Taekwondo Championships in Seoul as Singapore Team Coach. Korea Hapkido Association Jungmookwan 5th Dan. Hapkido Moohakkwan Open in Singapore.</p>

        <p className="mb-4"><strong>1974</strong>: Australia Taekwondo Jidokwan established. Australia Hapkido Moohakkwan established.</p>

        <p className="mb-4"><strong>1975</strong>: President of NSW State Taekwondo Association.</p>

        <p className="mb-4"><strong>1976</strong>: World Taekwondo Federation 7th Dan. Participation of Hapkido Soongmookwan Instructor Training in Korea.</p>

        <p className="mb-4"><strong>1978</strong>: Australian Taekwondo Association Vice President.| Korea Hapkido Association 6th Dan.</p>

        <p className="mb-4"><strong>1979</strong>: Australian Team Leader for World Taekwondo Championship in Taiwan.</p>

        <p className="mb-4"><strong>1983</strong>: Korea Judo Association 2nd Dan. Korea Hapkido Soongmookwan Instructor Training in Seoul. Korea Hapkido Association 7th Dan.</p>

        <p className="mb-4"><strong>1984</strong>: World Taekwondo Federation 8th Dan.</p>

        <p className="mb-4"><strong>1985</strong>: President of Australian Taekwondo Council.</p>

        <p className="mb-4"><strong>1986</strong>: Self-defence (Taekwondo) Part time Lecturer in Sydney University, Physical Education. President of Australian Hapkido Federation.</p>

        <p className="mb-4"><strong>1988</strong>: Korea Hapkido Federation 8th Dan Australian Team Leader to New Caledonia-Australian Taekwondo Events (Noumea)</p>

        <p className="mb-4"><strong>1993</strong>: President of Australian Taekwondo Union. International Invitation Taekwondo Championship Australian Team Leader in Cyprus. Appointed to Head Master of Taekwondo Jidokwan in Australia from Korea H.Q. Kukkiwon (World Taekwondo H.Q.) Taekwondo 9th Dan</p>

        <p className="mb-4"><strong>1996</strong>: Graded to 9th Dan by the Korea Hapkido Association</p>

        <p className="mb-4"><strong>2008</strong>: Kukkiwon International Advisory Committee appointed</p>

        <p className="mb-4"><strong>2010</strong>: Kukkiwon Examination Committee appointed</p>
      </div>

      <img
        className="hidden rounded-lg md:block md:shadow-lg md:shadow-stone-700/30"
        src="/img/Master-Sung-Soo-Lee.jpg"
        alt="Grand Master Sung Soo Lee"
      />
    </Section>
  </>
);

export default SungSooLee;
